import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

import Home from './pages/Home';

import NotFound from "./pages/NotFound";

class App extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            location: null,
        };

        this.updateLocations(window.location.pathname);
    }

    componentDidMount() {
        this.updateLocations(window.location.pathname);
    }

    updateLocations(location) {
        let locations = this.state.locations;

        this.setState({
            locations: locations
        });
    }

    changeLocation(location) {
        this.updateLocations(location);

        this.setState({
            location: location
        });
    }

    renderHeader() {
        return (
            <div className="header animated fadeInDown" id="header">
                <div className="container">
                    <div className="row">
                        <div className="col-8 col-lg-4">
                            <div className="logo" onClick={ () => this.changeLocation('/') }>
                              <h1>Visual Design</h1>
                              <p>Sam Grew</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Router>
                <div className="App">
                    <ToastContainer/>
                    { this.state.location ? (<Redirect to={ this.state.location } />) : null }

                    { this.renderHeader() }

                    <div className="container">
                        <Switch>
                            <Route exact path="/" component={ Home } />
                            <Route component={ NotFound } />
                        </Switch>
                    </div>
                </div>
            </Router>
        );
    }
}


export default withCookies(App);
