// Falling rain simulation using 2D canvas
// - vanilla JS, no frameworks
// - framerate independent physics
// - slow-mo / fast-forward support via simulation.speed
// - supports high-DPI screens
// - falling rain particles are drawn as vector lines
// - splash particles are lazily pre-rendered so gradients aren't computed each frame
// - all particles make use of object pooling to further boost performance

// initialize
document.addEventListener("DOMContentLoaded", function() {
    if (document.getElementById('rain-canvas')) {
        simulation.init();
        window.addEventListener('resize', simulation.resize);
    }
});

// simulation namespace
let simulation = {
    // CUSTOMIZABLE PROPERTIES
    // - physics speed multiplier: allows slowing down or speeding up simulation
    speed: 1,
    // - color of particles
    color: {
        r: '80',
        g: '175',
        b: '255',
        a: '0.5'
    },

    // END CUSTOMIZATION
    // whether simulation is running
    started: false,
    // canvas and associated context references
    canvas: null,
    ctx: null,
    // viewport dimensions (DIPs)
    width: 0,
    height: 0,
    // devicePixelRatio alias (should only be used for rendering, physics shouldn't care)
    dpr: window.devicePixelRatio || 1,
    // time since last drop
    drop_time: 0,
    // ideal time between drops (changed with mouse/finger)
    drop_delay: 25,
    // wind applied to rain (changed with mouse/finger)
    wind: 4,
    // color of rain (set in init)
    rain_color: null,
    rain_color_clear: null,
    // rain particles
    rain: [],
    rain_pool: [],
    // rain droplet (splash) particles
    drops: [],
    drop_pool: []
};

window.simulation = simulation;

// simulation initialization (should only run once)
simulation.init = function() {
    if (!simulation.started) {
        simulation.started = true;
        simulation.canvas = document.getElementById('rain-canvas');
        simulation.ctx = simulation.canvas.getContext('2d');
        let c = simulation.color;
        simulation.rain_color = 'rgba(' + c.r + ',' + c.g + ',' + c.b + ',' + c.a + ')';
        simulation.rain_color_clear = 'rgba(' + c.r + ',' + c.g + ',' + c.b + ',0)';
        simulation.resize();
        Ticker.addListener(simulation.step);
    }
};

// (re)size canvas (clears all particles)
simulation.resize = function() {
    // localize common references
    let rain = simulation.rain;
    let drops = simulation.drops;
    // recycle particles
    for (let i = rain.length - 1; i >= 0; i--) {
        rain.pop().recycle();
    }
    for (let i = drops.length - 1; i >= 0; i--) {
        drops.pop().recycle();
    }
    // resize
    simulation.width = window.innerWidth;
    simulation.height = window.innerHeight;
    simulation.canvas.width = simulation.width * simulation.dpr;
    simulation.canvas.height = simulation.height * simulation.dpr;
};

simulation.step = function(time, lag) {
    // localize common references
    let simulation = window.simulation;
    let speed = simulation.speed;
    let width = simulation.width;
    let height = simulation.height;
    let wind = simulation.wind;
    let rain = simulation.rain;
    let rain_pool = simulation.rain_pool;
    let drops = simulation.drops;
    // let drop_pool = simulation.drop_pool;

    // multiplier for physics
    let multiplier = speed * lag;

    // spawn drops
    simulation.drop_time += time * speed;
    while (simulation.drop_time > simulation.drop_delay) {
        simulation.drop_time -= simulation.drop_delay;
        let new_rain = rain_pool.pop() || new Rain();
        new_rain.init();
        let wind_expand = Math.abs(height / new_rain.speed * wind); // expand spawn width as wind increases
        let spawn_x = Math.random() * (width + wind_expand);
        if (wind > 0) spawn_x -= wind_expand;
        new_rain.x = spawn_x;
        rain.push(new_rain);
    }

    // rain physics
    for (let i = rain.length - 1; i >= 0; i--) {
        let r = rain[i];
        r.y += r.speed * r.z * multiplier;
        r.x += r.z * wind * multiplier;
        // remove rain when out of view
        if (r.y > height) {
            // if rain reached bottom of view, show a splash
            r.splash();
        }
        // recycle rain
        if (r.y > height + Rain.height * r.z || (wind < 0 && r.x < wind) || (wind > 0 && r.x > width + wind)) {
            r.recycle();
            rain.splice(i, 1);
        }
    }

    // splash drop physics
    let drop_max_speed = Drop.max_speed;
    for (let i = drops.length - 1; i >= 0; i--) {
        let d = drops[i];
        d.x += d.speed_x * multiplier;
        d.y += d.speed_y * multiplier;
        // apply gravity - magic number 0.3 represents a faked gravity constant
        d.speed_y += 0.3 * multiplier;
        // apply wind (but scale back the force)
        d.speed_x += wind / 25 * multiplier;
        if (d.speed_x < -drop_max_speed) {
            d.speed_x = -drop_max_speed;
        }else if (d.speed_x > drop_max_speed) {
            d.speed_x = drop_max_speed;
        }
        // recycle
        if (d.y > height + d.radius) {
            d.recycle();
            drops.splice(i, 1);
        }
    }

    simulation.draw();
};

simulation.draw = function() {
    // localize common references
    let simulation = window.simulation;
    let width = simulation.width;
    let height = simulation.height;
    let dpr = simulation.dpr;
    let rain = simulation.rain;
    let drops = simulation.drops;
    let ctx = simulation.ctx;

    // start fresh
    ctx.clearRect(0, 0, width*dpr, height*dpr);

    // draw rain (trace all paths first, then stroke once)
    ctx.beginPath();
    let rain_height = Rain.height * dpr;
    for (let i = rain.length - 1; i >= 0; i--) {
        let r = rain[i];
        let real_x = r.x * dpr;
        let real_y = r.y * dpr;
        ctx.moveTo(real_x, real_y);
        // magic number 1.5 compensates for lack of trig in drawing angled rain
        ctx.lineTo(real_x - simulation.wind * r.z * dpr * 1.5, real_y - rain_height * r.z);
    }
    ctx.lineWidth = Rain.width * dpr;
    ctx.strokeStyle = simulation.rain_color;
    ctx.stroke();

    // draw splash drops (just copy pre-rendered canvas)
    for (let i = drops.length - 1; i >= 0; i--) {
        let d = drops[i];
        let real_x = d.x * dpr - d.radius;
        let real_y = d.y * dpr - d.radius;
        ctx.drawImage(d.canvas, real_x, real_y);
    }
};


// Rain definition
function Rain() {
    this.x = 0;
    this.y = 0;
    this.z = 0;
    this.speed = 25;
    this.splashed = false;
};

Rain.width = 2;
Rain.height = 40;
Rain.prototype.init = function() {
    this.y = Math.random() * -100;
    this.z = Math.random() * 0.5 + 0.5;
    this.splashed = false;
};

Rain.prototype.recycle = function() {
    simulation.rain_pool.push(this);
};
// recycle rain particle and create a burst of droplets
Rain.prototype.splash = function() {
    if (!this.splashed) {
        this.splashed = true;
        let drops = simulation.drops;
        let drop_pool = simulation.drop_pool;

        for (let i=0; i<16; i++) {
            let drop = drop_pool.pop() || new Drop();
            drops.push(drop);
            drop.init(this.x);
        }
    }
};


// Droplet definition
function Drop() {
    this.x = 0;
    this.y = 0;
    this.radius = Math.round(Math.random() * 2 + 1) * simulation.dpr;
    this.speed_x = 0;
    this.speed_y = 0;
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');

    // render once and cache
    let diameter = this.radius * 2;
    this.canvas.width = diameter;
    this.canvas.height = diameter;

    let grd = this.ctx.createRadialGradient(this.radius, this.radius , 1, this.radius, this.radius, this.radius);
    grd.addColorStop(0, simulation.rain_color);
    grd.addColorStop(1, simulation.rain_color_clear);
    this.ctx.fillStyle = grd;
    this.ctx.fillRect(0, 0, diameter, diameter);
};

Drop.max_speed = 5;

Drop.prototype.init = function(x) {
    this.x = x;
    this.y = simulation.height;
    let angle = Math.random() * Math.PI - (Math.PI * 0.5);
    let speed = Math.random() * Drop.max_speed;
    this.speed_x = Math.sin(angle) * speed;
    this.speed_y = -Math.cos(angle) * speed;
};

Drop.prototype.recycle = function() {
    simulation.drop_pool.push(this);
};
//
// // handle interaction
// simulation.mouseHandler = function(evt) {
//     simulation.updateCursor(evt.clientX, evt.clientY);
// };
//
// simulation.touchHandler = function(evt) {
//     evt.preventDefault();
//     let touch = evt.touches[0];
//     simulation.updateCursor(touch.clientX, touch.clientY);
// };
//
// simulation.updateCursor = function(x, y) {
//     x /= simulation.width;
//     y /= simulation.height;
//     let y_inverse = (1 - y);
//
//     simulation.drop_delay = y_inverse*y_inverse*y_inverse * 100 + 2;
//     simulation.wind = (x - 0.5) * 50;
// };
//
// document.addEventListener('mousemove', simulation.mouseHandler);
// document.addEventListener('touchstart', simulation.touchHandler);
// document.addEventListener('touchmove', simulation.touchHandler);



// Frame ticker helper module
let Ticker = (function(){
    let PUBLIC_API = {};

    // public
    // will call function reference repeatedly once registered, passing elapsed time and a lag multiplier as parameters
    // eslint-disable-next-line
    PUBLIC_API.addListener = function addListener(fn) {
        if (typeof fn !== 'function') throw fn;

        listeners.push(fn);

        // start frame-loop lazily
        if (!started) {
            started = true;
            queueFrame();
        }
    };

    // private
    let started = false;
    let last_timestamp = 0;
    let listeners = [];
    // queue up a new frame (calls frameHandler)
    function queueFrame() {
        requestAnimationFrame(frameHandler);
    }
    function frameHandler(timestamp) {
        let frame_time = timestamp - last_timestamp;
        last_timestamp = timestamp;
        // make sure negative time isn't reported (first frame can be whacky)
        if (frame_time < 0) {
            frame_time = 17;
        }
        // - cap minimum framerate to 15fps[~68ms] (assuming 60fps[~17ms] as 'normal')
        else if (frame_time > 68) {
            frame_time = 68;
        }

        // fire custom listeners
        for (let i = 0, len = listeners.length; i < len; i++) {
            listeners[i].call(window, frame_time, frame_time / 16.67);
        }

        // always queue another frame
        queueFrame();
    }

    return PUBLIC_API;
}());