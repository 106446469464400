import React, { Component } from 'react';
import './styles/Navigator.css';

class Navigator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pages: [
                'Intro',
                'Work',
                // 'Contact',
                'Github',
                'Thank you'
            ],
            selectedPageId: 0
        };
        this.getPage = this.getPage.bind(this);
        this.selectPage = this.selectPage.bind(this);
        this.checkScrollPosition = this.checkScrollPosition.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.checkScrollPosition);
    }

    checkScrollPosition() {
        if (this.state.pages.length > 0) {
            this.state.pages.map((page, index) => {
                if (window.scrollY >= index * window.innerHeight - (window.innerHeight / 2) && window.scrollY <= index * (window.innerHeight * 2)) {
                    this.setState({ selectedPageId: index });
                }

                return true;
            });
        }
    }

    getPage(id) {
        if (this.state.selectedPageId === id) {
            return (
                <div key={ 'page-' + id }  className="location selected animated zoomIn">
                    <p>{ this.state.pages[id] }</p>
                </div>
            );
        }
        return (
            <div key={ 'page-' + id } className="location animated zoomIn" onClick={() => this.selectPage(id)}/>
        );
    }

    selectPage(id) {
        window.scrollTo(0, id * window.innerHeight);
        this.setState({ selectedPageId: id });
    }

    render() {
        return (
            <div>
                <div className="navigator d-none d-lg-block">
                    { this.state.pages.map((page, index) => {
                            return this.getPage(index);
                    }) }
                </div>
            </div>
        );
    }

}

export default Navigator;
