import React, { Component } from 'react';
import '../styles/App.css';
import './js/rain.js';

class NotFound extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div id="page" className="not-found">
                <h1>404 Not found....</h1>
                <p><b>Tough day?</b> We know it too well... Unfortunately this page is not here...</p>
                <a href="/" className="btn btn-primary"><i className="fas fa-home" /></a>
                <canvas id="rain-canvas" className="rain">Canvas is not supported in your browser</canvas>
            </div>
        );
    }
}

export default NotFound;