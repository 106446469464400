import React, { Component } from 'react';
import Confetti from 'react-dom-confetti';
import Skills from '../components/widgets/skills/Skills';
import GithubWidget from '../components/widgets/github/GithubWidget';
import Navigator from "../components/Navigator";
import '../styles/App.css';

const config = {
    angle: 360,
    spread: 360,
    startVelocity: 100,
    elementCount: 200,
    decay: 0.9
};

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confettiDeployed: false,
            confetti: false,
            header: []
        };

        this.buttonClick = this.buttonClick.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        this.setState({header: document.getElementById('header')});
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        if (window.scrollY > document.body.offsetHeight - 1500 && !this.state.confetti) {
            this.buttonClick();
            this.setState({"confetti": true});
        }
    }

    buttonClick() {
        if (!this.state.confettiDeployed) {
            this.setState({ confettiDeployed: true });
            window.setTimeout(() => {
                this.setState({confettiDeployed: !this.state.confettiDeployed});
            }, 2000);
        }
    }

    render() {
        return (
            <div>
                <Navigator />
                <div className="container">
                    <div className="stage">
                        <div className="intro-widget animated zoomIn">
                            <div className="content">
                                <h1>Visual Design</h1>
                                <p>Sam Grew</p>
                            </div>
                        </div>
                        <Skills/>
                    </div>

                    <div className="stage">
                        <div className="sub-header">
                            <h1>Previous Work</h1>
                        </div>

                        <div className="previous-work-widget">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-4">
                                    <div className="card animated zoomIn">
                                        <h1>Visual Design</h1>
                                        <p>
                                            My own personal site which has now undergone a complete redesign!
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="card glide-student animated zoomIn">
                                        <h1>Glide Student</h1>
                                        <p>
                                            In 2017 Glide Student needed a reskin which was undertaken by me. The site has
                                            now been reskinned again but I am constantly improving the user experience on
                                            Glide’s Site.
                                        </p>
                                        <a rel="noopener noreferrer" target="_blank" href="https://glide.co.uk" className="btn">
                                            <span>View</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="card animated zoomIn">
                                        <h1>Opensource</h1>
                                        <p>
                                            My Github houses multiple projects which are all open source!
                                        </p>
                                        <a rel="noopener noreferrer" target="_blank" href="https://github.com/AceXintense" className="btn">
                                            <span>View</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="stage">
                    <div className="sub-header">
                        <h1>Github</h1>
                    </div>

                    <GithubWidget username="AceXintense"/>
                </div>

                <div className="stage">
                    <div className="thank-you-widget">
                        <div className="container">
                            <Confetti className="confetti" config={config} active={this.state.confettiDeployed}/>
                            <div className={this.state.confettiDeployed ? 'animated jackInTheBox' : ''}>
                                <h3 onClick={this.buttonClick}>
                                    Thank you <span role="img" aria-label="Confetti">🎉</span>
                                </h3>
                                <p>Hope you have found what you were looking for!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;