import React, { Component } from 'react';
import './styles/Skill.css';

class Skill extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.getColor = this.getColor.bind(this);
    }
    getColor() {
        return {
            "backgroundImage": "linear-gradient(-134deg," + this.props.color[0] + " 0%," + this.props.color[1] + " 100%)",
            "height": this.props.height + "%"
        }
    }
    render() {
        return (
            <div className="skill animated bounceInUp" style={ this.getColor() }>
                <h1 className="d-none d-lg-block">{ this.props.skillName }</h1>

                <h3 className="skill-mobile d-block d-sm-none">{ this.props.skillName }</h3>
                <h3 className="skill-tablet d-none d-sm-block d-lg-none">{ this.props.skillName }</h3>
            </div>
        );
    }
}

export default Skill;
