import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { CookiesProvider } from 'react-cookie';

const Root = (
    <CookiesProvider>
        <App />
    </CookiesProvider>
);

ReactDOM.render(Root, document.getElementById('root'));
registerServiceWorker();
