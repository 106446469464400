import React, { Component } from 'react';
import Skill from './Skill';
import './styles/Skills.css';

const skills = [
    [
        {
            "name": "HTML",
            "color": ["#B4EC51", "#429321"],
            "height": 45
        },
        {
            "name": "AngularJS",
            "color": ["#FAD961", "#F76B1C"],
            "height": 35
        }
    ],
    [
        {
            "name": "PHP",
            "color": ["#3023AE", "#C86DD7"],
            "height": 65
        },
        {
            "name": "React",
            "color": ["#61BDFF", "#4A90E2"],
            "height": 30
        }
    ],
    [
        {
            "name": "CSS",
            "color": ["#FAD961", "#F76B1C"],
            "height": 25
        },
        {
            "name": "Javascript",
            "color": ["#F5515F", "#9F041B"],
            "height": 55
        }
    ]
];

class Skills extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    static getSpacingHeight(index) {
        let skillRows = skills[index];
        let percent = 100;
        if (skillRows.length > 0) {
            skillRows.map((skill) => {
                percent -= skill.height;
                return true;
            });
        }
        return {
            "height": "calc(" + percent + "% - 30px)"
        };
    }
    render() {
        return (
            <div className="skills">
                <div className="row">
                    { skills.map(function(row, index) {
                        return (
                            <div key={index + 'row'} className="col-4">
                                <div className="vertical-row">
                                    <div className="spacer" style={ Skills.getSpacingHeight(index) }/>
                                    { row.map((skill, index) => {
                                        return (
                                            <Skill key={ skill.name + index } skillName={ skill.name } color={ skill.color } height={ skill.height } />
                                        );
                                    }) }
                                </div>
                            </div>
                        );
                    }) }
                </div>
            </div>
        );
    }
}

export default Skills;
