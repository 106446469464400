import React, { Component } from 'react';
import axios from 'axios';
import './styles/GithubWidget.css';
import _ from 'lodash';

const APIUrl = 'https://api.github.com';
/*
    Github Widget - Connects to Github's API and provides back the data for the user.
 */
class GithubWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            repositories: [],
            filteredRepositories: [],
            pageCount: 0,
            selectedPage: 0,
            searchOpen: false,
            searchValue: '',
            tags: []
        };

        // Register Functions.
        this.changePageLeft = this.changePageLeft.bind(this);
        this.changePageRight = this.changePageRight.bind(this);
        this.toggleSearch = this.toggleSearch.bind(this);
        this.closeRepository = this.closeRepository.bind(this);
        this.addSearchTag = this.addSearchTag.bind(this);
    }

    isMobile() {
        return window.innerWidth <= 575;
    }

    isTablet() {
        return window.innerWidth > 575 && window.innerWidth <= 992;
    }

    /*
        Call Github for the data when rendered.
     */
    componentDidMount() {
        axios.get(APIUrl + '/users/' + this.props.username + '/repos').then(
            success => {
                let chunkSize = 3;

                // If mobile only show one on the featured page.
                if (this.isMobile()) {
                    chunkSize = 1;
                }

                if (this.isTablet()) {
                    chunkSize = 2;
                }

                //Store it just for caching purposes.
                window.localStorage.setItem('githubWidgetData', JSON.stringify(success.data));
                let repositories = _.chunk(success.data, chunkSize);
                this.setState({
                    repositories: repositories,
                    pageCount: repositories.length
                });
            },
            error => {
                this.setFromCache();
            }
        ).then(() => {
            let languageTags = [];
            if (this.state.repositories.length > 0) {
                this.state.repositories.map((row) => {
                    if (row.length > 0) {
                        row.map((repository) => {
                            // Not found in the language tags add it.
                            if (languageTags.indexOf(repository.language) === -1 && repository.language !== null) {
                                languageTags.push(repository.language);
                            }

                            return true;
                        });
                    }

                    return true;
                });

                this.setState({
                    tags: languageTags
                });
            }
        });
    }

    /*
        Gets the repository data from the local storage if we cannot get the data from Github.
     */
    setFromCache() {
        let cachedRepositories = JSON.parse(window.localStorage.getItem('githubWidgetData'));
        let chunkSize = 3;

        // If mobile only show one on the featured page.
        if (this.isMobile()) {
            chunkSize = 1;
        }

        if (this.isTablet()) {
            chunkSize = 2;
        }

        let repositories = _.chunk(cachedRepositories, chunkSize);
        this.setState({
            repositories: repositories,
            pageCount: repositories.length
        });
    }

    /*
        Changes page - 1
     */
    changePageLeft() {
        let selectedPage = this.state.selectedPage;
        this.setState({ selectedPage: selectedPage -= 1 });
    }

    /*
        Changes page + 1
     */
    changePageRight() {
        let selectedPage = this.state.selectedPage;
        this.setState({ selectedPage: selectedPage += 1 });
    }

    /*
        Toggles search on and off.
     */
    toggleSearch() {
        this.setState({ searchOpen: !this.state.searchOpen });
    }

    /*
        Opens the search box.
     */
    openSearch() {
        this.setState({ searchOpen: true });
    }

    /*
        Handle Search searches over the array we received from Github looking for the Name and Language.
     */
    handleSearch(e) {
        let searchValue = e.target.value;
        let filteredRepositories = this.filterRepositories(searchValue);
        let pageCount = filteredRepositories.length;

        this.setState({
            searchValue: searchValue,
            filteredRepositories:  filteredRepositories,
            selectedPage: 0,
            pageCount: pageCount
        });
    }

    /*
        Filters the repositories data to the structure we need.
     */
    filterRepositories(filter) {
        let filteredRepositories = _.flatten(this.state.repositories).map((repository) => {
            // Returns a repository if the name or the language matches.
            if (_.toLower(repository.name).indexOf(_.toLower(filter)) >= 0 || _.toLower(repository.language).indexOf(_.toLower(filter)) >= 0) {
                return repository;
            }
        });
        return _.chunk(_.compact(filteredRepositories), 3);
    }

    /*
        Repositories DOM this render's the individual cards with the data passed from Github.
     */
    getRepositories() {
        if (this.state.searchValue.length <= 0) {
            // If the search is empty just render normal cards.
            return _.map(this.state.repositories[this.state.selectedPage], repository => {
                return (
                    <div className="col-sm-6 col-lg-4" key={ 'repository-' + repository.id }>
                        <div className={ _.toLower(repository.language) + ' repository animated zoomIn foreground-' + this.state.repositoryOpen}>
                            <h1>{ repository.name.replace(/-/g, ' ') }</h1>
                            <p>{ repository.description }</p>
                            <div className="btn" onClick={ () => this.openRepository(repository) }>
                                <span>Open</span>
                            </div>
                        </div>
                    </div>
                );
            });
        } else {
            // If the search is not empty render filtered cards.
            if (this.state.filteredRepositories.length > 0) {
                return _.map(this.state.filteredRepositories[this.state.selectedPage], repository => {
                    return (
                        <div className="col-sm-6 col-lg-4" key={ 'repository-' + repository.id }>
                            <div className={ _.toLower(repository.language) + ' repository animated zoomIn' }>
                                <h1>{ repository.name }</h1>
                                <p>{ repository.description }</p>
                                <div className="btn" onClick={ () => this.openRepository(repository) }>
                                    <span>Open</span>
                                </div>
                            </div>
                        </div>
                    );
                });
            } else {
                return (<h1 className="not-found">"{ this.state.searchValue }" could not be found </h1>);
            }
        }
    }

    /*
        Opens a dialog for the specific card.
     */
    openRepository(repository) {
        window.open(repository.html_url, '_blank');
        // this.setState({ openedRepository: {}, repositoryOpen: false }, () => {
        //     this.setState({ openedRepository: repository, repositoryOpen: true });
        // });
    }

    /*
        Closes the dialog for the selected card.
     */
    closeRepository() {
        this.setState({ openedRepository: {}, repositoryOpen: false });
    }

    /*
        Left button only render if not on mobile.
     */
    leftButton() {
        if (!this.isMobile()) {
            return (
                <img src={"images/LeftButton.svg"} className="left-button animated zoomIn"
                     onClick={this.changePageLeft} alt=""
                     style={{display: this.state.selectedPage > 0 ? 'block' : 'none'}}/>
            );
        }
        return null;
    }

    /*
        Left button only render if not on mobile.
     */
    rightButton() {
        if (!this.isMobile()) {
            return (
                <img src={"images/RightButton.svg"} className="right-button animated zoomIn"
                     onClick={this.changePageRight} alt=""
                     style={{display: this.state.selectedPage < this.state.pageCount - 1 ? 'block' : 'none'}}/>
            );
        }
        return null;
    }

    addSearchTag(tag) {
        this.openSearch();
        let filteredRepositories = this.filterRepositories(tag);
        this.setState({
            searchValue: tag,
            filteredRepositories:  filteredRepositories,
            selectedPage: 0,
            pageCount: filteredRepositories.length
        });
    }

    recommendedSearchTags() {
        return (
            <div className="tags">
                <p>Quick Search Terms</p>
                {
                    this.state.tags.map((tag, index) => {
                        return (
                            <div className="tag animated zoomIn" key={ 'tag-' + index } onClick={ () => this.addSearchTag(tag) }>
                                <p>{ tag }</p>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    /*
        Overarching renderer for the widget.
     */
    render() {
        return (
            <div className="github-widget">
                <div className="container">
                    <div className={'input-group animated zoomIn ' + (this.state.searchOpen ? 'open' : '')}>
                        <input type="text" value={ this.state.searchValue } onChange={ this.handleSearch.bind(this) }/>
                        <img src="images/Search.svg" alt="" onClick={ this.toggleSearch }/>
                    </div>

                    { this.leftButton() }

                    <div className="row">
                        { this.getRepositories() }
                    </div>

                    { this.rightButton() }

                    { this.recommendedSearchTags() }
                </div>
            </div>
        );
    }
}

export default GithubWidget;
